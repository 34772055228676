import React from 'react'
import logo from './BelliesAtelierLogo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
        Hi, welkom bij Bellie's Atelier
        </h1>
        <p>
        Hou deze pagina in de gaten of snuf eens op mijn instagram!
        </p>
        <a
          className="App-link"
          href="https://www.instagram.com/bellies_atelier/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Volg mijn instagram
        </a>
      </header>
    </div>
  );
}

export default App;
